<template>
  <star-rating
    v-bind:rating="parseInt(value)"
    v-bind:read-only="true"
    v-bind:increment="0.01"
    v-bind:star-size="20"
    v-bind:show-rating="false"
  ></star-rating>
</template>
<script>
export default {
  name: "TableStar",
  props: {
    value: {
      default: 0,
    },
  },
};
</script>
